import { useId } from 'react';

import dynamic from 'next/dynamic';
import { SwiperSlide } from 'swiper/react';

import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  HeroBlockFragment,
  SliderBlockFragment,
} from '@hultafors/hellberg/types';

import { HeroBlock } from '../HeroBlock/HeroBlock';

import styles from './slider-block.module.scss';

const SliderBlockShared = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SliderBlockShared),
);

export const SliderBlock: React.FC<SliderBlockFragment> = ({
  arrows,
  dots,
  delay,
  slides: heroes,
  slideAnimation,
  title,
}) => {
  const uid = useId();
  const { shared: global } = useGlobal();

  function heroMapper(hero: HeroBlockFragment, index: number) {
    return (
      <SwiperSlide className="swiper-slide" key={`SliderBlock-${uid}-${index}`}>
        <HeroBlock {...hero} priority={index === 0} isInSlider />
      </SwiperSlide>
    );
  }

  return (
    <SliderBlockShared
      blockProps={{
        arrows,
        delay,
        dots,
        slideAnimation,
      }}
      ariaLabelNext={global?.nextLabel || ''}
      ariaLabelPrevious={global?.previousLabel || ''}
      ariaLabelSliderNavigation={global?.sliderNavigationLabel || ''}
      className={styles['root']}
    >
      {heroes.map(heroMapper)}
    </SliderBlockShared>
  );
};
